.pay {
    .text {
        margin-bottom: 20px;

        p {
            font-size: 30px;
            margin-bottom: 0;
        }

        .unit-price {
            color: #0b74cc;
        }
    }

    .card {
        border-radius: 12px;
        cursor: pointer;
    }

    .card,
    .border-bottom {
        border-width: 2px !important;
        border-color: $color_fpt_gray !important;
    }

    .card-title {
        margin: 0;
        font-weight: 700;
    }

    .vnpay {
        .card-title {
            color: #005bab;
        }
    }

    .momo {
        .card-title {
            color: #ae2070;
        }
    }

    .active {
        border-color: #000 !important;
    }

    .grayscale {
        filter: grayscale(1);
    }

    &-success {
        .bg {
            font-size: 36px;
            color: #0b74cc;
            font-weight: 500;
            background: url(../images/orther/check-ok.png) no-repeat center;
            background-size: 100px;
        }        
    }

    &-fail {
        .bg {
            font-size: 36px;
            font-weight: 500;
            background: url(../images/orther/error.png) no-repeat center;
            background-size: 100px;
        }   
    }
}
