body {
    font-family: $roboto;
    padding-top: 76px;

    @include media-breakpoint-down(sm) {
        padding-top: 66px;
    }
}

#header {
    .lang img {
        width: 40px;
    }
    .navbar-toggler {
        padding: 0;
        margin: 0;
        border: 0 none;
        color: $color;
        font-size: 2rem;
    }
    .navbar-nav {
        .nav-link {
            color: #000;
            margin: 0 1rem;
        }
        li:last-child {
            .nav-link {
                margin-right: 0;
            }
        }
    }

    .navbar-light .navbar-nav .nav-link:focus,
    .navbar-light .navbar-nav .nav-link:hover {
        color: #f8c132;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.23);
    }

    .navbar-light .navbar-nav .active > .nav-link,
    .navbar-light .navbar-nav .nav-link.active,
    .navbar-light .navbar-nav .nav-link.show,
    .navbar-light .navbar-nav .show > .nav-link {
        color: #f8c132;
    }

    @include media-breakpoint-up(xl) {
        .navbar-nav .nav-link {
            padding-left: 15px;
            padding-right: 15px;
            font-size: 20px;
        }
    }

    @include media-breakpoint-down(sm) {
        .logo {
            height: 40px;
        }
    }
}
